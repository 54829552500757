import { render, staticRenderFns } from "./historicoAlteracoes.vue?vue&type=template&id=e0e3ea4a&scoped=true"
import script from "./historicoAlteracoes.vue?vue&type=script&lang=js"
export * from "./historicoAlteracoes.vue?vue&type=script&lang=js"
import style0 from "./historicoAlteracoes.vue?vue&type=style&index=0&id=e0e3ea4a&lang=scss&scoped=true"
import style1 from "./historicoAlteracoes.vue?vue&type=style&index=1&id=e0e3ea4a&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0e3ea4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\OnPoint-Front\\OnPoint-Front\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e0e3ea4a')) {
      api.createRecord('e0e3ea4a', component.options)
    } else {
      api.reload('e0e3ea4a', component.options)
    }
    module.hot.accept("./historicoAlteracoes.vue?vue&type=template&id=e0e3ea4a&scoped=true", function () {
      api.rerender('e0e3ea4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/parametrizacao/components/modalidade/historicoAlteracoes.vue"
export default component.exports