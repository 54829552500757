<template>
  <div>
    <div class="clause">
      <div class="vx-row p-3">
        <div class="w-full p-2">
          <vs-input
            class="w-full mb-10"
            label="Nome da condição"
            size="large"
            v-model="objectData.Name"
            maxlength="200"
          />

          <vs-row class="mb-5">
            <vs-switch
              :disabled="objectData.IsFixedClause"
              v-model="objectData.AllowBrokerSelection"
            ></vs-switch>
            <label class="ml-2"> Permitido Corretora Selecionar</label>
          </vs-row>

          <vs-row class="mb-5">
            <vs-tooltip
              position="right"
              text="Caso habilitado, a proposta cairá para mesa de subscrição caso a cláusula tenha sido selecionada"
            >
              <vs-switch
                :disabled="objectData.IsFixedClause"
                v-model="objectData.AllowAutomaticIssue"
              ></vs-switch>
            </vs-tooltip>
            <label class="ml-2">Emissão automática</label>
          </vs-row>

          <vs-row class="mb-5">
            <vs-switch
              v-model="objectData.IsFixedClause"
              @change="setRequired()"
            ></vs-switch>
            <label class="ml-2"> Esta Cláusula deve ser fixa</label>
          </vs-row>
        </div>
      </div>

      <contractual-term
        :allowedRepeater="true"
        :allowedTextArea="true"
        :jsonTagsAll="jsonTagsAll"
        :valueTerm="objectData.ContractualTerm.Value"
        :contractualTerm="objectData.ContractualTerm"
        @setJSONTagsAll="setJSONTagsAll"
        @setValueTerm="setValueTerm"
      />

      <div class="pt-4 mt-5 flex flex-row-reverse w-full">
        <el-button type="primary" @click="saveObject()" :loading="loadingButton"
          >Salvar condição</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import ContractualTerm from "./ContractualTerm.vue";

export default {
  components: { "v-select": vSelect, ContractualTerm },
  props: {
    objectData: Object
  },
  data() {
    return {
      jsonTagsAll: [],
      loadingButton: false
    };
  },
  mounted() {
    this.jsonTagsAll =
      this.objectData.ContractualTerm.JSONTag != null
        ? eval(this.objectData.ContractualTerm.JSONTag)
        : [];
  },
  methods: {
    ...mapActions("seguradora-module", ["saveParticularClauses"]),

    setJSONTagsAll(value) {
      this.jsonTagsAll = value;
    },
    setValueTerm(value) {
      this.objectData.ContractualTerm.Value = value;
    },

    setRequired() {
      if (this.objectData.IsFixedClause) {
        this.objectData.AllowBrokerSelection = false;
        this.objectData.AllowAutomaticIssue = false;
      }
    },

    async saveObject() {
      let error = false;
      if (!this.objectData.ContractualTerm.Value || !this.objectData.Name) {
        error = true;
      }
      if (this.jsonTagsAll) {
        this.jsonTagsAll.map(
          tag => (error = tag.Label == "" || tag.Name == "" ? true : false)
        );
      }

      if (error) {
        this.$onpoint.errorModal("Preencha todos os campos.");
        return;
      }

      this.setRequired();

      let data = {
        Value: this.objectData.ContractualTerm.Value,
        Name: this.objectData.Name,
        JSONTag:
          this.jsonTagsAll && this.jsonTagsAll.length
            ? JSON.stringify(this.jsonTagsAll)
            : "",
        ModalityId: parseInt(this.$route.params.modalidadeId),
        AllowBrokerSelection: this.objectData.AllowBrokerSelection,
        AllowAutomaticIssue: this.objectData.AllowAutomaticIssue,
        Id: this.objectData.Id,
        ContractualTermId: this.objectData.ContractualTerm.Id,
        IsFixedClause: !!this.objectData.IsFixedClause,
        EnableToProposal: this.objectData.EnableToProposal
      };
      this.loadingButton = true;
      this.$vs.loading();
      await this.$onpoint.loading(async () => {
        return await this.saveParticularClauses(data)
          .then(resp => {
            this.$onpoint.successModal("Dados inseridos com sucesso.");
            this.loadingButton = false;
            this.$vs.loading.close();
            this.$emit("getClauses");
          })
          .catch(ex => {
            this.loadingButton = false;
            this.$vs.loading.close();
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-repeater-fields {
  z-index: 60000;
}

.switch-container {
  display: inline-flex;
}
</style>
