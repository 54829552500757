<template>
  <section class="mt-4">
    <div class="table-content new-history">
      <h2 class="font-bold">
        Historico de Alterações
      </h2>
      <template v-if="historico.length > 0">
        <vs-table
          :data="historico"
          :hoverFlat="true"
          noDataText="Nenhuma histórico encontrado"
        >
          <template slot="thead">
            <vs-th>Usuário</vs-th>
            <vs-th class="text-center">Data da alteração</vs-th>
            <vs-th></vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td :data="data[indextr].ChangedByName">
                {{ item.ChangedByName || "-" }}
              </vs-td>

              <vs-td :data="item.ChangedAt" class="text-left">
                {{ item.ChangedAt | moment("DD/MM/YYYY") }}
              </vs-td>

              <vs-td class="text-right">
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="btn-action-apolice"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu class="btn-action-apolice-options">
                    <vs-dropdown-item>
                      <p
                        class="text-base text-nowrap"
                        @click="openModalAlteracoes(item)"
                      >
                        <span>Visualizar alterações</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <vs-pagination
          class="mt-5"
          :total="numberOfPages"
          v-model="currentPage"
          v-if="numberOfPages > 1"
        />

        <vs-popup
          id="listar-alteracoes"
          title="Histórico de alterações"
          :active.sync="showModal"
        >
          <div class="vw-row mt-3">
            <div class="vx-col pt-5 w-full mt-2 new-history">
              <ul class="lista-alteracoes">
                <li
                  class="alteracao"
                  :key="index"
                  v-for="(item, index) in alteracoesVisializacao"
                >
                  <div class="flex">
                    <div class="item">
                      <p>Usuário:</p>
                      <b>{{ item.ChangedByName }}</b>
                    </div>
                    <div class="item">
                      <p>Hora da alteração:</p>
                      <b> {{ setMascaraHoras(item.ChangedTime) }}</b>
                    </div>
                  </div>
                  <div class="alterou" v-if="modalityStatus(item)">
                    Alterou o campo <b>{{  $utils.statusHistoryName(item)}}</b> de 
                    <b>{{ item.PreviousValue }}</b>
                    para
                    <b>{{ item.NewValue }} </b>
                  </div>
                  <div class="alterou" v-else>
                    Alterou o campo <b>{{ modalityStatusName(item) }}</b>
                  </div>
                  <div class="body" v-if="!modalityStatus(item)">
                    <div>
                      <vs-collapse type="line">
                        <vs-collapse-item>
                          <div slot="header">
                            <b>Antes</b>
                          </div>
                            <jodit-editor   read-only="true" v-model="item.PreviousValue"/>
                        </vs-collapse-item>
                        <vs-collapse-item>
                          <div slot="header">
                            <b>Depois</b>
                          </div>
                             <jodit-editor   read-only="true" v-model="item.NewValue"/>
                        </vs-collapse-item>
                      </vs-collapse>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </vs-popup>
      </template>

      <template v-else>
        <div class="flex flex-col w-full md:w-1/2 mx-auto items-center mt-32">
          <p><span class="material-icons text-6xl">description</span></p>
          <p class="text-center">
            Nenhuma alteração foi realizada para essa modalidade.
          </p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import optionsStaticMixin from "@/mixins/optionsStaticMixin";
import _ from "lodash";
import { VueEditor, Quill } from "vue2-editor";
const Block = Quill.import("blots/block");
Block.tagName = "DIV";
Quill.register(Block, true);
export default {
  name: "apolices-segurado",
  components: {
    VueEditor
  },
  props: { id: { type: Number, require: true } },
  mixins: [optionsStaticMixin],
  mounted() {
    this.listarHitorico();
  },
  data() {
    return {
      historico: [],
      alteracoesVisializacao: [],
      showModal: false,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      customToolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
      ]
    };
  },
  methods: {
    ...mapActions("parametrizacao-module", ["getHistoryModality"]),
    modalityStatus(item) {
      switch (item.Subject) {
        case "ContractualTermData-0":
          return false;
          break;
        case "ContractualTermData-1":
          return false;
          break;
        case "ContractualTermData-2":
          return false;
          break;
        case "ContractualTermData-3":
          return false;
          break;
        case "ContractualTermData-4":
          return false;
          break;
        case "ContractualTermData-5":
          return false;
          break;
        case "ContractualTermData-6":
          return false;
          break;
        default:
          return true;
      }
    },
    modalityStatusName(item) {
      return "Objeto";
      // switch (item.Subject) {
      //   case "ContractualTermData-0":
      //     return "Objeto";
      //     break;
      //   case "ContractualTermData-1":
      //     return false;
      //     break;
      //   case "ContractualTermData-2":
      //     return false;
      //     break;
      //   case "ContractualTermData-3":
      //     return false;
      //     break;
      //   case "ContractualTermData-4":
      //     return false;
      //     break;
      //   case "ContractualTermData-5":
      //     return false;
      //     break;
      //   case "ContractualTermData-6":
      //     return false;
      //     break;
      //   default:
      //     return 'Campo';
      // }
    },
    async listarHitorico() {
      await  this.$onpoint.loading(async() => {
        return await this.getHistoryModality(
          this.id
          // currentPage: this.currentPage,
          // rowsPerPage: this.rowsPerPage
        )
          .then(response => {
            this.historico = response;
            this.numberOfPages = response.NumberOfPages;
          })
          .catch(err => {
            this.$onpoint.errorModal(err.response.data.Errors);
          });
      });
    },

    openModalAlteracoes(item) {
      this.showModal = true;
      this.alteracoesVisializacao = (
        item.ModalityChangesHistories || []
      ).concat(item.LocationChangesHistories || []);

      var data = _.uniqWith(
        this.alteracoesVisializacao,
        a => a.Column == "JSONTag"
      );
      this.alteracoesVisializacao = data;
    },

    setMascaraHoras(val) {
      // Replace impossible inputs as the appear
      val = val.replace(/[^\dh:]/, "");
      val = val.replace(/^[^0-2]/, "");
      val = val.replace(/^([2-9])[4-9]/, "$1");
      val = val.replace(/^\d[:h]/, "");
      val = val.replace(/^([01][0-9])[^:h]/, "$1");
      val = val.replace(/^(2[0-3])[^:h]/, "$1");
      val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
      val = val.replace(/^(\d{2}h)./, "$1");
      val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
      val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
      return val.slice(0, 5);
    }
  }
};
</script>

<style lang="scss" scoped>
.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>

<style lang="scss">
.tree-view-item-key-with-chevron {
  color: red;
}

.tree-view-item-key-with-chevron[data-v-efc5bae2]::before {
  content: "\f067" !important;
  font-family: FontAwesome;
}

.tree-view-item-key-with-chevron.opened[data-v-efc5bae2]::before {
  content: "\f068" !important;
  transform: none;
  font-family: FontAwesome;
}

#listar-alteracoes .vs-popup {
  min-width: 85% !important;
  min-height: 85%;
}
.alteracao .item {
  margin-right: 100px;
  margin-bottom: 10px;
}
.alterou b {
  color: rgba(var(--vs-primary), 1);
}
.alteracao .body {
  background: #f8f8f8;
  margin-top: 15px;
  /* grayscale/gray-2 */

  border: 1px solid #e4e1e1;
  border-radius: 8px;
}
.alteracao {
  margin-bottom: 15px;
  border-left: 4px solid #dfdfdf;
  cursor: pointer;
  padding: 15px 10px;
  font-size: 18px;
}
.alteracao:hover {
  border-left: 4px solid rgba(var(--vs-primary), 1);
  background: rgba(250, 250, 250, 0.672);
}
.vs-popup--title h3 {
  font-weight: bold !important;
}
</style>
