<template>
  <section class="documents">
    <div class="header">
      <div class="pt-4 mt-5 flex flex-row-reverse w-full">
        <vs-button @click="openModalDocumentos(documentDefault)"
          >Adicionar Documento</vs-button
        >
      </div>
    </div>
    <div class="document-list">
      <template v-if="documentsArr">
        <vs-table :data="documentsArr" noDataText="Nenhum documento cadastrado">
          <template slot="header">
            <h4 class="ml-2 mb-2">
              Documentos
            </h4>
          </template>

          <template slot="thead">
            <vs-th class="blue-header">
              Nome
            </vs-th>
            <vs-th class="blue-header">
              Esteiras
            </vs-th>
            <vs-th class="blue-header">
              Data de cadastro
            </vs-th>
            <vs-th class="blue-header">
              Status
            </vs-th>
            <vs-th class="blue-header">
              Ações
            </vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
              <vs-td>
                {{ item.Name }}
              </vs-td>
              <vs-td>
                {{ getEsteiras(item.PipeIdList || []) }}
              </vs-td>
              <vs-td>
                {{ item.CreateDate | moment("DD/MM/YYYY") }}
              </vs-td>
              <vs-td>
                {{ !item.IsDeleted ? "Ativo" : "Inativo" }}
              </vs-td>
              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    style="width: 200px; z-index: 53001 !important;"
                  >
                    <vs-dropdown-item @click="openModalDocumentos(item)">
                      <p class="text-base px-6 py-2">
                        <span>Editar</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="openModalDelete(item)">
                      <p class="text-base px-6 py-2">
                        <span>
                          {{ item.IsDeleted ? "Ativar" : "Inativar" }}</span
                        >
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </div>

    <vs-popup
      title="Documento"
      class="popup-document"
      :active.sync="modalDocumentos"
    >
      <ValidationObserver v-slot="{ invalid }">
        <div class="vx-row p-3">
          <ValidationProvider
            name="nome"
            rules="required"
            v-slot="{ errors }"
            tag="div"
            class="w-full p-2"
          >
            <vs-input
              class="w-full "
              label="Nome"
              size="large"
              :danger="!!errors[0]"
              :danger-text="errors[0]"
              v-model="documentObj.Name"
            />
          </ValidationProvider>

          <ValidationProvider
            name="esteiras"
            rules="required"
            v-slot="{ errors }"
            tag="div"
            class="w-full p-2"
          >
            <p>Esteiras</p>
            <v-select
              label="Name"
              size="large"
              placeholder="Selecione uma ou mais esteiras"
              multiple
              v-model="documentObj.PipeIdList"
              :options="pipesArr"
              :reduce="pipes => pipes.Id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
            />

            <span class="text-danger text-sm" v-show="errors[0]">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
        </div>

        <div class="vx-row p-5">
          <vs-switch v-model="documentObj.IsRequired"></vs-switch>
          <label class="ml-2"> Obrigatório</label>
        </div>

        <div class="vx-row p-3">
          <div class="flex flex-row-reverse w-full mr-3">
            <vs-button :disabled="invalid" @click="saveDocument(documentObj)"
              >Salvar</vs-button
            >
          </div>
        </div>
      </ValidationObserver>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title=""
      :active.sync="modalDelete"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full flex justify-center mb-10">
          <h4 class="text-center">
            Deseja realmente
            {{ dataStatus.isDeleted ? "inativar" : "ativar" }} o documento
            <b>{{ dataStatus.name }}</b
            >?
          </h4>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="cancelDelete()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="confirmDelete()" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data() {
    return {
      modalDocumentos: false,
      documentObj: Object,
      documentDefault: {
        IsRequired: true,
        IsDeleted: false,
        ModalityId: this.$route.params.modalidadeId,
        Name: "",
        Description: ""
      },
      documentsArr: [],
      pipesArr: [],
      modalDelete: false,
      dataStatus: {},
      documentId: null,
      documentName: null
    };
  },
  computed: {
    ...mapGetters("pipe-module", ["pipes"]),
    ...mapGetters("parametrizacao-module", ["generalConditions"])
  },
  async mounted() {
    this.getDocuments();
    await this.getPipeType();
  },
  methods: {
    ...mapActions("seguradora-module", ["updateModalityDocument"]),
    ...mapActions("parametrizacao-module", [
      "saveModalityDocument",
      "getProposalParameterRequestDocuments",
      "getPipeTypeForDocument",
      "updateStatusModalityDocument"
    ]),
    openModalDelete(document) {
      this.modalDelete = true;
      this.dataStatus = {
        isDeleted: !document.IsDeleted,
        name: document.Name,
        id: document.Id
      };
    },

    cancelDelete() {
      this.modalDelete = false;
      this.documentName = "";
      this.documentId = null;
    },

    getEsteiras(pipes) {
      let pipesName = [];
      this.pipesArr.forEach(x => {
        if (pipes.includes(x.Id)) pipesName.push(x.Name);
      });
      return pipesName.join(", ");
    },

    confirmDelete() {
      this.updateStatusDocument();
    },

    openModalDocumentos(documentObj) {      
      this.modalDocumentos = true;
      this.documentObj = this.$utils.deepClone(documentObj);
    },

    async saveDocument(document) {
     await this.$onpoint.loading(async() => {
        if (!document.Id) {
          return await this.saveModalityDocument(document)
            .then(resp => {
              this.modalDocumentos = false;
              this.documentObj = {};
              this.getDocuments();
              this.$onpoint.successModal("Documento adicionado com sucesso.");
              this.$forceUpdate();
            })
            .catch(ex => {
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        } else {
          return await this.updateModalityDocument(document)
            .then(resp => {
              this.modalDocumentos = false;
              this.documentObj = {};
              this.getDocuments();
              this.$onpoint.successModal("Documento atualizado com sucesso.");
              this.$forceUpdate();
            })
            .catch(ex => {
              this.$onpoint.errorModal(
                ex.response.data.Errors.length
                  ? ex.response.data.Errors
                  : ex.response.data.Response
              );
            });
        }
      });
    },

    async getDocuments() {
      await this.$onpoint.loading(async () => {
        return await this.getProposalParameterRequestDocuments(
          this.$route.params.modalidadeUniqueId
        )
          .then(response => {
            this.documentsArr = response;
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    },
    async getPipeType() {
      await this.$onpoint.loading(async() => {
        return await this.getPipeTypeForDocument()
          .then(response => {
            this.pipesArr = response;
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    },

    async updateStatusDocument() {
      this.modalDelete = false;
      this.$forceUpdate();
      await this.$onpoint.loading(async() => {
        return await this.updateStatusModalityDocument(this.dataStatus)
          .then(resp => {
            this.getDocuments();
            this.$onpoint.successModal(
              `Documento ${
                !this.dataStatus.isDeleted ? "ativado" : "inativado"
              } com sucesso!`
            );
            this.dataStatus = {};
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          });
      });
    }
  }
};
</script>

<style lang="scss">
.success-modal,
.error-modal {
  z-index: 60000;
}
.popup-document {
  .vs-popup {
    width: 500px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .vs-popup--content {
    max-height: calc(100% - 150px);
    padding: 30px;
  }

  .vs-popup--header {
    padding: 30px;
  }

  .v-select .vs__dropdown-menu {
    z-index: 100 !important;
    display: block;
    height: auto;
    position: absolute;
  }

  .vs__dropdown-toggle {
    min-height: 46.8px;
  }

  .v-select .vs__dropdown-toggle .vs__selected-options .vs__selected {
    height: fit-content;
  }

  .vs__dropdown-toggle,
  .v-select .vs__dropdown-toggle .vs__selected-options .vs__selected {
    width: auto;
  }
}
</style>

<style lang="scss" scoped>
.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>