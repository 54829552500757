var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "mt-4" }, [
    _c(
      "div",
      { staticClass: "table-content new-history" },
      [
        _c("h2", { staticClass: "font-bold" }, [
          _vm._v("\n      Historico de Alterações\n    "),
        ]),
        _vm.historico.length > 0
          ? [
              _c(
                "vs-table",
                {
                  attrs: {
                    data: _vm.historico,
                    hoverFlat: true,
                    noDataText: "Nenhuma histórico encontrado",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function ({ data }) {
                          return _vm._l(data, function (item, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr, attrs: { data: item } },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    attrs: {
                                      data: data[indextr].ChangedByName,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.ChangedByName || "-") +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  {
                                    staticClass: "text-left",
                                    attrs: { data: item.ChangedAt },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("moment")(
                                            item.ChangedAt,
                                            "DD/MM/YYYY"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _c(
                                  "vs-td",
                                  { staticClass: "text-right" },
                                  [
                                    _c(
                                      "vs-dropdown",
                                      {
                                        staticClass: "btn-action-apolice",
                                        attrs: {
                                          "vs-custom-content": "",
                                          "vs-trigger-click": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "vs-button",
                                          {
                                            attrs: {
                                              color: "dark",
                                              type: "flat",
                                              title: "Ações",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "onpoint-dots-three-vertical icon-dots-three-vertical",
                                              attrs: { role: "button" },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "vs-dropdown-menu",
                                          {
                                            staticClass:
                                              "btn-action-apolice-options",
                                          },
                                          [
                                            _c("vs-dropdown-item", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "text-base text-nowrap",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openModalAlteracoes(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "Visualizar alterações"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          })
                        },
                      },
                    ],
                    null,
                    false,
                    469585603
                  ),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", [_vm._v("Usuário")]),
                      _c("vs-th", { staticClass: "text-center" }, [
                        _vm._v("Data da alteração"),
                      ]),
                      _c("vs-th"),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm.numberOfPages > 1
                ? _c("vs-pagination", {
                    staticClass: "mt-5",
                    attrs: { total: _vm.numberOfPages },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  })
                : _vm._e(),
              _c(
                "vs-popup",
                {
                  attrs: {
                    id: "listar-alteracoes",
                    title: "Histórico de alterações",
                    active: _vm.showModal,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.showModal = $event
                    },
                  },
                },
                [
                  _c("div", { staticClass: "vw-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col pt-5 w-full mt-2 new-history" },
                      [
                        _c(
                          "ul",
                          { staticClass: "lista-alteracoes" },
                          _vm._l(
                            _vm.alteracoesVisializacao,
                            function (item, index) {
                              return _c(
                                "li",
                                { key: index, staticClass: "alteracao" },
                                [
                                  _c("div", { staticClass: "flex" }, [
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", [_vm._v("Usuário:")]),
                                      _c("b", [
                                        _vm._v(_vm._s(item.ChangedByName)),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "item" }, [
                                      _c("p", [_vm._v("Hora da alteração:")]),
                                      _c("b", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.setMascaraHoras(
                                                item.ChangedTime
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm.modalityStatus(item)
                                    ? _c("div", { staticClass: "alterou" }, [
                                        _vm._v(
                                          "\n                  Alterou o campo "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$utils.statusHistoryName(item)
                                            )
                                          ),
                                        ]),
                                        _vm._v(" de \n                  "),
                                        _c("b", [
                                          _vm._v(_vm._s(item.PreviousValue)),
                                        ]),
                                        _vm._v(
                                          "\n                  para\n                  "
                                        ),
                                        _c("b", [
                                          _vm._v(_vm._s(item.NewValue) + " "),
                                        ]),
                                      ])
                                    : _c("div", { staticClass: "alterou" }, [
                                        _vm._v(
                                          "\n                  Alterou o campo "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(_vm.modalityStatusName(item))
                                          ),
                                        ]),
                                      ]),
                                  !_vm.modalityStatus(item)
                                    ? _c("div", { staticClass: "body" }, [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "vs-collapse",
                                              { attrs: { type: "line" } },
                                              [
                                                _c(
                                                  "vs-collapse-item",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "header",
                                                        },
                                                        slot: "header",
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Antes"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c("jodit-editor", {
                                                      attrs: {
                                                        "read-only": "true",
                                                      },
                                                      model: {
                                                        value:
                                                          item.PreviousValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "PreviousValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.PreviousValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "vs-collapse-item",
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "header",
                                                        },
                                                        slot: "header",
                                                      },
                                                      [
                                                        _c("b", [
                                                          _vm._v("Depois"),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c("jodit-editor", {
                                                      attrs: {
                                                        "read-only": "true",
                                                      },
                                                      model: {
                                                        value: item.NewValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "NewValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.NewValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          : [_vm._m(0)],
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto items-center mt-32",
      },
      [
        _c("p", [
          _c("span", { staticClass: "material-icons text-6xl" }, [
            _vm._v("description"),
          ]),
        ]),
        _c("p", { staticClass: "text-center" }, [
          _vm._v(
            "\n          Nenhuma alteração foi realizada para essa modalidade.\n        "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }