var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "horizontal-tabs-components",
        { attrs: { tabs: _vm.tabs } },
        [
          _c("modality-details", {
            attrs: {
              slot: "tab-content-0",
              details: _vm.detalhes || {},
              complementary: _vm.isComplementaryModality,
              mainModalityId: _vm.isComplementaryModality
                ? _vm.mainModalityId
                : null,
              permissoesModalidade: _vm.permissoesModalidade,
            },
            on: {
              modalitySaved: function ($event) {
                return _vm.$emit("modalitySaved", $event)
              },
              "update-details": _vm.updateDetails,
            },
            slot: "tab-content-0",
          }),
          !!_vm.detalhes.Id
            ? [
                _c(
                  "div",
                  { attrs: { slot: "tab-content-1" }, slot: "tab-content-1" },
                  [
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          { attrs: { "vs-w": "12", "vs-sm": "12" } },
                          [
                            _c("vs-input", {
                              staticClass: "w-full p-1",
                              attrs: { label: "Referência da Seguradora" },
                              model: {
                                value: _vm.objeto.RefIntegration,
                                callback: function ($$v) {
                                  _vm.$set(_vm.objeto, "RefIntegration", $$v)
                                },
                                expression: "objeto.RefIntegration",
                              },
                            }),
                            _c("br"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("contractual-term", {
                      attrs: {
                        allowedRepeater: false,
                        allowedTextArea: _vm.allowedTextArea,
                        jsonTagsAll: _vm.allTags,
                        valueTerm: _vm.objeto.Value,
                        contractualTerm: _vm.objeto,
                      },
                      on: {
                        setJSONTagsAll: _vm.setJSONTagsAll,
                        setValueTerm: _vm.setValueTerm,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.loadingButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveObject(2)
                              },
                            },
                          },
                          [_vm._v("Salvar objeto")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { slot: "tab-content-2" }, slot: "tab-content-2" },
                  [
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          { attrs: { "vs-w": "12", "vs-sm": "12" } },
                          [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("vs-switch", {
                                  staticClass: "mr-3",
                                  model: {
                                    value: _vm.CondicoesGerais.EnableToProposal,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.CondicoesGerais,
                                        "EnableToProposal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "CondicoesGerais.EnableToProposal",
                                  },
                                }),
                                _c("label", [_vm._v("Habilitar na Proposta")]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          { attrs: { "vs-w": "12", "vs-sm": "12" } },
                          [
                            _c("vs-input", {
                              staticClass: "w-full p-1",
                              attrs: { label: "Referência da Seguradora" },
                              model: {
                                value: _vm.CondicoesGerais.RefIntegration,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.CondicoesGerais,
                                    "RefIntegration",
                                    $$v
                                  )
                                },
                                expression: "CondicoesGerais.RefIntegration",
                              },
                            }),
                            _c("br"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("contractual-term", {
                      attrs: {
                        allowedRepeater: false,
                        allowedTextArea: _vm.allowedTextArea,
                        jsonTagsAll: _vm.allTagsCondicoesGerais,
                        valueTerm: _vm.CondicoesGerais.Value,
                        contractualTerm: _vm.CondicoesGerais,
                      },
                      on: {
                        setJSONTagsAll: _vm.setJSONTagsAllCondicoesGerais,
                        setValueTerm: _vm.setValueTermCondicoesGerais,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "pt-4 mt-5 flex flex-row-reverse w-full" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              loading: _vm.loadingButton,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.saveObject(1)
                              },
                            },
                          },
                          [_vm._v("Salvar objeto")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { attrs: { slot: "tab-content-3" }, slot: "tab-content-3" },
                  [
                    _vm.condicoesEspeciais
                      ? [
                          _c(
                            "vs-row",
                            [
                              _c(
                                "vs-col",
                                { attrs: { "vs-w": "12", "vs-sm": "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex" },
                                    [
                                      _c("vs-switch", {
                                        staticClass: "mr-3",
                                        model: {
                                          value:
                                            _vm.condicoesEspeciais
                                              .EnableToProposal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.condicoesEspeciais,
                                              "EnableToProposal",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "condicoesEspeciais.EnableToProposal",
                                        },
                                      }),
                                      _c("label", [
                                        _vm._v("Habilitar na Proposta"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "vs-row",
                            [
                              _c(
                                "vs-col",
                                { attrs: { "vs-w": "12", "vs-sm": "12" } },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full",
                                    attrs: {
                                      label: "Referência da Seguradora",
                                    },
                                    model: {
                                      value:
                                        _vm.condicoesEspeciais.RefIntegration,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.condicoesEspeciais,
                                          "RefIntegration",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "condicoesEspeciais.RefIntegration",
                                    },
                                  }),
                                  _c("br"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    _c("contractual-term", {
                      attrs: {
                        allowedRepeater: false,
                        allowedTextArea: _vm.allowedTextArea,
                        jsonTagsAll: _vm.allTagsCondicoesEspeciais,
                        valueTerm: _vm.condicoesEspeciais.Value,
                        contractualTerm: _vm.condicoesEspeciais,
                      },
                      on: {
                        setJSONTagsAll: _vm.setJSONTagsAllCondicoesEspeciais,
                        setValueTerm: _vm.setValueTermCondicoesEspeciais,
                      },
                    }),
                    _c(
                      "vs-row",
                      [
                        _c(
                          "vs-col",
                          { attrs: { "vs-type": "flex", "vs-justify": "end" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loadingButton,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.saveObject(3)
                                  },
                                },
                              },
                              [_vm._v("Salvar condições especiais")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _c("condicoes-particulares", {
                  attrs: { slot: "tab-content-4" },
                  slot: "tab-content-4",
                }),
                _c("documentos-obrigatorios", {
                  attrs: { slot: "tab-content-5" },
                  slot: "tab-content-5",
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }