<template>
  <section class="clause">
    <div class="header">
      <div class="pt-4 mt-5 flex flex-row-reverse w-full">
        <vs-button @click="openModalClauses(defaultClauseObject)"
          >Adicionar Condição</vs-button
        >
      </div>
    </div>

    <div class="clauses-list">
      <template v-if="clausesList.length > 0">
        <vs-table :data="clausesList">
          <template slot="header">
            <h4 class="ml-2 mb-2">Condições Particulares</h4>
          </template>

          <template slot="thead">
            <vs-th> Condição </vs-th>
            <vs-th> Data de cadastro </vs-th>
            <vs-th> Status </vs-th>
            <vs-th> Ações </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].Name">
                {{ data[indextr].Name }}
              </vs-td>
              <vs-td :data="data[indextr].ContractualTerm.CreateDate">
                {{
                  data[indextr].ContractualTerm.CreateDate
                    | moment("DD/MM/YYYY")
                }}
              </vs-td>
              <vs-td>
                {{
                  data[indextr].ContractualTerm.IsDeleted ? "Inativa" : "Ativa"
                }}
              </vs-td>
              <vs-td>
                <vs-dropdown
                  vs-custom-content
                  vs-trigger-click
                  class="cursor-pointer"
                >
                  <vs-button color="dark" type="flat" title="Ações">
                    <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
                  </vs-button>
                  <vs-dropdown-menu
                    style="width: 200px; z-index: 53001 !important"
                  >
                    <vs-dropdown-item
                      @click="openModalClauses(data[indextr])"
                    >
                      <p class="text-base px-6 py-2">
                        <span>Editar</span>
                      </p>
                    </vs-dropdown-item>
                    <vs-dropdown-item
                      @click="
                        openModalDelete(
                          data[indextr].Name,
                          data[indextr].Id,
                          !data[indextr].ContractualTerm.IsDeleted ||
                          data[indextr].IsActive
                        )
                      "
                    >
                      <p class="text-base px-6 py-2">
                        <span>Inativar/Ativar</span>
                      </p>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </div>

    <vs-popup
      title=""
      class="popup-particular-clauses"
      :active.sync="modalClauses"
    >
      <h3 class="p-2 mb-5">Condição Particular</h3>
      <div>
        <objeto-tags
          v-if="modalClauses"
          @getClauses="getClauses"
          :objectData="clauseObject"
        />
      </div>
    </vs-popup>

    <vs-popup
      class="validacao-data-vigente-modal"
      title=""
      :active.sync="modalDelete"
    >
      <div class="vw-row mt-3">
        <div class="vx-col w-full flex justify-center mb-10">
          <h4 class="text-center">
            Deseja realmente {{ clauseIsDeleted ? "ativar" : "inativar" }} a
            condição <b>{{ clauseName }}</b
            >?
          </h4>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="cancelDelete()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="confirmDelete(clauseId)" class="button-primary">
            Confirmar
          </vs-button>
        </div>
      </div>
    </vs-popup>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import ObjetoTags from "../ObjetoTags.vue";

export default {
  components: {
    "objeto-tags": ObjetoTags
  },
  props: {
    coverage: Array
  },
  data() {
    return {
      objectTags: Array,
      modalityId: this.$route.params.modalidadeId,
      modalClauses: false,
      clausesList: [],
      clauseObject: {},
      modalDelete: false,
      clauseIsDeleted: "",
      clauseName: "",
      clauseId: null,
      defaultClauseObject: {
        Name: "",
        IsFixedClause: true,
        ContractualTerm: {
          JSONTag: null,
          Value: ""
        }
      }
    };
  },
  mounted() {
    this.getClauses();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getParticularClauses",
      "getModalityObject",
      "updateStatusParticularClause"
    ]),
    openModalDelete(coverageName, clauseId, isDeleted) {
      this.modalDelete = true;
      this.clauseName = coverageName;
      this.clauseId = clauseId;
      this.clauseIsDeleted = isDeleted;
    },
    cancelDelete() {
      this.modalDelete = false;
      this.clauseName = "";
      this.clauseId = null;
      this.clauseIsDeleted = null;
    },
    confirmDelete(clauseId) {
      this.deleteClauses(clauseId);
    },
    openModalClauses(clauseObject) {
      this.clauseObject = this.$utils.deepClone(clauseObject);
      this.modalClauses = true;
    },
    async getClauses() {
      this.modalClauses = false;
      await this.$onpoint.loading(async() => {
        return await this.getParticularClauses({
          modalityId: this.modalityId
        }).then(clauses => {
          this.clausesList = clauses;
          this.$forceUpdate();
        });
      });
    },
    async deleteClauses(clauseId) {
      await this.$onpoint.loading(async() => {
        return await this.updateStatusParticularClause({
          Id: clauseId,
          Active: !this.clauseIsDeleted
        })
          .then(resp => {
            this.getClauses();
            this.$onpoint.successModal(
              `Condição ${
                !this.clauseIsDeleted ? "inativada" : "ativada"
              } com sucesso.`
            );
          })
          .catch(ex => {
            this.$onpoint.errorModal(
              ex.response.data.Errors.length
                ? ex.response.data.Errors
                : ex.response.data.Response
            );
          })
          .finally(() => {
            this.modalDelete = false;
            this.clauseName = "";
            this.clauseId = null;
            this.clauseIsDeleted = null;
            this.$forceUpdate();
          });
      });
    }
  }
};
</script>
<style lang="scss">
.success-modal,
.error-modal {
  z-index: 60000;
}
.popup-particular-clauses {
  .vs-popup {
    width: 900px !important;
    height: max-content;
  }
  .vs-popup--content {
    max-height: calc(100vh - 150px);
  }
}
#objeto,
#condicoes {
  height: 450px;
}
</style>

<style lang="scss" scoped>
.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>