var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._l(_vm.tag.Repeater, function (item, index) {
        return _c("div", { key: index, staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-4/12 w-full" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { size: "large", label: "Label" },
                on: {
                  input: function ($event) {
                    return _vm.setInputTag({ Label: $event }, item)
                  },
                  change: function ($event) {
                    return _vm.setName(item, $event.target.value)
                  },
                },
                model: {
                  value: item.Label,
                  callback: function ($$v) {
                    _vm.$set(item, "Label", $$v)
                  },
                  expression: "item.Label",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-5/12 w-full pb-5" },
            [
              _c("vs-input", {
                staticClass: "w-full",
                attrs: { size: "large", label: "Tag", value: item.Name },
                on: {
                  change: function ($event) {
                    return _vm.setName(item, $event.target.value)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-2/12 w-full pb-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Selecione o tipo"),
              ]),
              _c("v-select", {
                staticClass: "w-full",
                attrs: {
                  label: "name",
                  size: "large",
                  placeholder: "Tipo",
                  options: _vm.options,
                  dir: _vm.$vs.rtl ? "rtl" : "ltr",
                },
                on: {
                  input: function ($event) {
                    return _vm.selectedType($event, index)
                  },
                },
                model: {
                  value: item.Type,
                  callback: function ($$v) {
                    _vm.$set(item, "Type", $$v)
                  },
                  expression: "item.Type",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/12 w-full m-auto" },
            [
              _c(
                "vs-row",
                [
                  item.Type == "Repeater"
                    ? _c("vs-button", {
                        staticClass: "vs-col md:w-1/12 w-full m-auto",
                        attrs: {
                          type: "flat",
                          icon: "view_agenda",
                          size: "large",
                        },
                        nativeOn: {
                          click: function ($event) {
                            _vm.repeaterFieldsDialog = !_vm.repeaterFieldsDialog
                          },
                        },
                      })
                    : _vm._e(),
                  _c("vs-button", {
                    attrs: { type: "flat", icon: "close", size: "large" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.removeTag(item, index)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ])
      }),
      _c(
        "div",
        { staticClass: "p-4 mt-5 flex flex-row-reverse w-full" },
        [
          _c(
            "vs-button",
            {
              staticClass: "px-1",
              attrs: { type: "flat", icon: "add_circle", "icon-after": true },
              on: {
                click: function ($event) {
                  return _vm.addTag()
                },
              },
            },
            [_vm._v("\n      Incluir TAG")]
          ),
        ],
        1
      ),
      _c("jodit-editor", {
        staticStyle: { width: "100%" },
        attrs: { config: _vm.joditConfig, id: "objeto" },
        model: {
          value: _vm.tag.Text,
          callback: function ($$v) {
            _vm.$set(_vm.tag, "Text", $$v)
          },
          expression: "tag.Text",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }